import { CommandBarButton, IButtonStyles, IPersonaProps, ITextFieldProps, Label, Persona, PersonaSize, Stack } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FC, useContext, useEffect, useState } from "react";
import UserProfile from "../../Models/UserProfile";
import UserService from "../../Services/UserService";
import { store } from "./GlobalStateProvider";
import { MsPeoplePickerComponent } from "./MsPeoplePickerComponent";

interface IMeetingRequestorComponent {
    onUserSelected: (selectedUser: UserProfile) => void,
}

export const MeetingRequestorComponent: FC<IMeetingRequestorComponent> = (props: IMeetingRequestorComponent): JSX.Element => {
    let userService: UserService = new UserService();
    var { appState, appdispatch } = useContext(store);

    const loadUserProfile = async () => {
        var user = await userService.getUserProfile();
        requestorUserProfileSet(user);
    };

    useEffect(() => {
        loadUserProfile();
    }, []);

    const [editRequestorText, editRequestorTextSet] = useState<string>('Edit Requestor');
    const [isEditingRequestorProfile, isEditingRequestorProfileSet] = useState<boolean>(false);
    const editRequestorLabel = useId('editrequestorlabel');
    const [requestorUserProfile, requestorUserProfileSet] = useState<UserProfile>();
    const [requestorPersona, requestorPersonaSet] = useState<IPersonaProps>(
        {
            text: appState.userProfile?.displayName
        });
    useEffect(() => {

        if (requestorUserProfile && !requestorUserProfile?.photo && requestorUserProfile?.userPrincipalName) {
            userService.getUserPhotoByUpn(requestorUserProfile?.userPrincipalName).then((str) => {
                if (str) {

                    requestorPersonaSet({
                        size: PersonaSize.size32,
                        imageUrl: str,
                        text: requestorUserProfile?.displayName
                    });
                    
                }
            });

            props.onUserSelected(requestorUserProfile);
        }


        console.log('requestor profile set to false')
        isEditingRequestorProfileSet(false);
    }, [requestorUserProfile]);

    useEffect(() => {
        if (isEditingRequestorProfile) {
            editRequestorTextSet('Stop Editing');
        }
        else {
            editRequestorTextSet('Edit Requestor');
        }
    }, [isEditingRequestorProfile]);
    const EditRequestorLabel = (props: ITextFieldProps): JSX.Element => {
        const editRequestorLabel = useId('editrequestorlabel');
        const iconButtonStyles: Partial<IButtonStyles> = { root: { marginBottom: 4 } };
        return (
            <>
                <Stack horizontal >
                    <Label id={props.id}>{props.label}</Label>
                    <CommandBarButton
                        className="ms-auto"
                        style={{ marginTop: 10 }}
                        id="EditRequestor"
                        iconProps={{ iconName: "Edit" }}
                        text={editRequestorText}
                        ariaLabel="Edit the requestor"
                        styles={iconButtonStyles}
                        onClick={onEditRequestor}
                    />
                </Stack>
            </>
        );
    }
    const onEditRequestor = async () => {
        isEditingRequestorProfileSet(!isEditingRequestorProfile);
    }

    return (
        <div>
            {
                (appState.userMetadata?.isAdmin || appState.userMetadata?.isReviewer) &&
                <EditRequestorLabel id={editRequestorLabel} label="Requestor" {...props} />
            }
            {
                (!appState.userMetadata?.isAdmin && !appState.userMetadata?.isReviewer) &&
                <Label title="Requestor">Requestor</Label>
            }

            {
                !isEditingRequestorProfile &&
                <Persona style={{ marginTop: 20, marginBottom: 10 }}
                    {...requestorPersona}
                    size={PersonaSize.size32}
                />
            }

            {
                isEditingRequestorProfile &&
                <MsPeoplePickerComponent
                    itemLimit={1}
                    selectedUsers={undefined}
                    isEditable={true}
                    onUserSelected={(selectedUsers: UserProfile[]) => {
                        if (selectedUsers && selectedUsers[0]) {
                            requestorUserProfileSet(selectedUsers[0]);
                        }
                    }} />
            }
        </div>
    );
}